/* @font-face {
    font-family: "SilkaBold";
    src: url("./assets/fonts/SlikaRegular/silka-regular-webfont.woff")
        format("truetype");
    font-style: bold;
    font-display: swap;
    font-weight: 900;
} */

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
    background-color: #2196f3 !important;
    border-color: #2196f3 !important;
}
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border-color: #2196f3 !important;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    border: 2px solid #2196f3 !important;
}
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a,
#CookiebotWidget .CookiebotWidget-consent-details button,
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover,
#CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBulkConsentList dt a,
#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a,
#CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonIABDescription a,
#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialogDetailBodyContentTextAbout a {
    color: #2196f3 !important;
}
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
    fill: #2196f3 !important;
}
#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
    border-bottom: 1px solid #2196f3 !important;
    color: #2196f3 !important;
}